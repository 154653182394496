<template>
    <div class="add-device">
        <div class="add-device-head">
            <div class="add-device-head-title">添加设备</div>
            <div class="add-device-head-close" @click="closeDialog">
                <img :src="closeIcon" class="add-device-head-close-img">
            </div>

        </div>
        <div style="width: 100%;height:1px ;background-color:#97979728 ;"></div>
        <div class="add-device-body">
            <p style="color: #E34D59;font-size: 14px;">*</p>
            <p>序列号：</p>
            <el-input v-model="deviceSN" placeholder="请输入" class="add-device-body-input" />
        </div>
        <div class="add-device-button">
            <button class="add-device-button-cancel" @click="closeDialog">取消</button>
            <button class="add-device-button-confirm" @click="confirmDialog">确定</button>
        </div>
    </div>
</template>

<script>
import { Input, Message } from 'element-ui';

export default {

    components: {
        elInput: Input,
        elMessage: Message,
    },
    data() {
        return {
            deviceSN: '',
            closeIcon: require("@imgs/holography-case-report-alert-close.png"),
        }
    },
    computed: {
        hospitalId() {
            return this.$store.state.organizationModel?.id;
        }
    },
    methods: {
        closeDialog() {
            this.$emit("onCloseDialog", false);
            this.deviceSN = ''
        },
        confirmDialog() {
            if (this.deviceSN == '') {
                //请输入设备序列号
                this.$message.closeAll()
                this.$message({
                    showClose: true,
                    grouping: true,
                    message: '请输入设备序列号',
                    type: 'error',

                })
                return;
            }
            this.addDevice()
        },
        // method
        async addDevice() {
            try {
                const params = {
                    sn: this.deviceSN,
                    hospitalId: this.hospitalId
                };
                await this.$api.addDevice(params);
                this.deviceSN = ''
                this.$emit("addDeviceCloseDialog", false);
            } catch (error) {
                this.$message.closeAll()
                this.$message({
                    showClose: true,
                    grouping: true,
                    message: error,
                    type: 'error',

                })
            }
        },

    },


};
</script>

<style lang="scss" scoped>
.add-device {
    width: 365px;
    height: 181px;
    background: #FFFFFF;
    border-radius: 4px;
    font-size: 14px;

    &-head {
        display: flex;
        height: 60px;
        font-size: 16px;
        font-weight: 500;
        color: black;
        align-items: center;
        justify-content: space-between;
        padding: 0px 27px 0px 27px;

        &-close {
            height: 18px;
            width: 18px;
            position: relative;
            cursor: pointer;

            &-img {
                width: 11px;
                height: 11px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }

    }

    &-body {
        margin-top: 18px;
        display: flex;
        color: black;
        font-size: 14px;
        align-items: center;
        justify-content: center;

        /* 水平居中 */
        &-input {
            width: 240px;
            height: 32px;

            ::v-deep .el-input__inner {
                height: 100%;
                color: black;
                font-size: 14px;

            }
        }
    }

    &-button {
        width: 100%;
        display: flex;
        margin-top: 18px;
        justify-content: flex-end;

        &-cancel {
            width: 60px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 3px;
            border: 1px solid rgba(151, 151, 151, 0.17);
            color: black;
            margin-right: 8px;
        }

        &-confirm {
            width: 60px;
            height: 32px;
            background: #2779FF;
            border-radius: 3px;
            color: white;
            margin-right: 27px;
        }
    }
}
</style>