<template>
    <div class="delete-device">
        <div class="delete-device-head">
            <div class="delete-device-head-title">删除设备</div>
            <div class="delete-device-head-close" @click="closeDialog">
                <img :src="closeIcon" class="delete-device-head-close-img">
            </div>

        </div>
        <div style="width: 100%;height:1px ;background-color:#97979728 ;"></div>
        <div class="delete-device-body">
            <p>是否删除设备？</p>
        </div>
        <div class="delete-device-button">
            <button class="delete-device-button-cancel" @click="closeDialog">取消</button>
            <button class="delete-device-button-confirm" @click="confirmDialog">确定</button>
        </div>
    </div>
</template>
<script>
import { Message } from 'element-ui';
export default {

    props: {
        deviceId: Number,
    },
    components: {
        elMessage: Message,
    },
    data() {
        return {

            closeIcon: require("@imgs/holography-case-report-alert-close.png"),
        }
    },
    methods: {
        closeDialog() {
            this.$emit("onCloseDeleteDialog", false);
        },
        confirmDialog() {
            console.log('删除', this.deviceSN);
            this.deleteDevice()
        },
        // method
        async deleteDevice() {
            try {
                const params = {
                    id: this.deviceId,
                };
                await this.$api.deleteDevice(params);
                this.$emit("deleteDeviceCloseDialog", false);
            } catch (error) {
                this.$message.closeAll();
                this.$message({
                    showClose: true,
                    grouping: true,
                    message: error,
                    type: 'error',

                });
            }
        },

    },

};
</script>
<style lang="scss" scoped>
.delete-device {
    width: 365px;
    height: 181px;
    background: #FFFFFF;
    border-radius: 4px;
    font-size: 14px;

    &-head {
        display: flex;
        height: 60px;
        font-size: 16px;
        font-weight: 500;
        color: black;
        align-items: center;
        justify-content: space-between;
        padding: 0px 27px 0px 27px;

        &-close {
            height: 18px;
            width: 18px;
            position: relative;
            cursor: pointer;

            &-img {
                width: 11px;
                height: 11px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }

    }

    &-body {
        margin-top: 23px;
        display: flex;
        color: red;
        font-size: 16px;
        align-items: center;
        justify-content: center;
        /* 水平居中 */

    }

    &-button {
        width: 100%;
        display: flex;
        margin-top: 23px;
        justify-content: flex-end;

        &-cancel {
            width: 60px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 3px;
            border: 1px solid rgba(151, 151, 151, 0.17);
            color: black;
            margin-right: 8px;
        }

        &-confirm {
            width: 60px;
            height: 32px;
            background: red;
            border-radius: 3px;
            color: white;
            margin-right: 27px;
        }
    }
}
</style>