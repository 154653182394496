<template>
  <div class="device-list">
    <div class="device-list-search">
      <div class="device-list-search-input">
        <el-input v-model="searchContent" class="device-list-search-input-input" placeholder="姓名或设备号查询"
          @keyup.enter.native="searchName" />
        <div class="device-list-search-input-container" @click="searchName">
          <img class="device-list-search-input-container-icon" :src="searchIcon">
        </div>

      </div>

      <button class="device-list-search-btn" @click="addDevice">添加设备</button>
    </div>
    <div class="device-list-table">
      <el-table :data="dataArray" border tooltip-effect="light myTooltips">
        <el-table-column prop="deviceSN" label="设备序列号" min-width="320/1318%">
          <template slot-scope="scope">
            <div style="display: flex">
              <img class="device-list-table-check-icon"
                :src="scope.row.onlineStatus == 'ONLINE' ? onlineIcon : offlineIcon">
              <div class="device-list-table--check-button">{{ scope.row.deviceSN }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="用户姓名" min-width="180/1318%">
        </el-table-column>
        <el-table-column prop="lastTime" label="最后使用" min-width="198/1318%">
        </el-table-column>
        <el-table-column prop="hospitalName" label="机构" min-width="470/1318%">
        </el-table-column>
        <el-table-column prop="operate" label="操作" min-width="150/1318%">
          <template slot-scope="scope">
            <button class="device-list-table-delete" @click="onDelete(scope.row.id)" style="color: #E34D59;">删除</button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <pagination v-if="totalPage != 0" class="device-list-pagination" :total="totalPage" :pageSize="10"
      :currentPage="currentPage" @pagechange="onPageChange"></pagination>

    <el-dialog :visible.sync="dialogVisible" class="device-list-dialog" width="365px" :close-on-click-modal="false">
      <add-device @onCloseDialog="onCloseDialog" @addDeviceCloseDialog="addDeviceCloseDialog" />
    </el-dialog>
    <el-dialog :visible.sync="deleteDialogVisible" class="device-list-dialog" width="365px"
      :close-on-click-modal="false">
      <delete-device :deviceId="deleteId" @onCloseDeleteDialog="onCloseDeleteDialog"
        @deleteDeviceCloseDialog="deleteDeviceCloseDialog" />
    </el-dialog>
  </div>
</template>


<script>


import Pagination from "@c/common/paging-list/pagination.vue";
import { Table, TableColumn, Input, Dialog, Message } from "element-ui";
import { DateTool } from "@js/date-tool.js";
import addDevice from "./add-device.vue";
import deleteDevice from "./delete-device.vue";
export default {
  components: {
    addDevice,
    deleteDevice,
    Pagination,
    elTable: Table,
    elTableColumn: TableColumn,
    elInput: Input,
    elDialog: Dialog,
    elMessage: Message,
  },
  data() {
    return {
      dialogVisible: false,
      deleteDialogVisible: false,
      currentPage: 1,
      totalPage: 0,
      total: undefined,
      searchContent: '',
      keyword: '',
      deleteId: '',
      searchIcon: require("@imgs/device-search.png"),
      dataArray: [],
      onlineIcon: require("@imgs/device-online.png"),
      offlineIcon: require("@imgs/device-offline.png"),
    }
  },
  mounted() {
    this.getDevicePagingList()
  },
  computed: {
    hospitalId() {
      return this.$store.state.organizationModel?.id;
    }
  },

  watch: {
    hospitalId(value) {
      this.searchContent = '';
      this.keyword = '';
      this.currentPage = 1;
      this.getDevicePagingList();
    }
  },
  methods: {
    onPageChange: function (newPage) {
      this.currentPage = newPage;
      this.getDevicePagingList();
    },
    searchName() {
      this.keyword = this.searchContent
      this.currentPage = 1
      this.getDevicePagingList()
    },
    addDevice() {
      this.hospitalId ? this.dialogVisible = true : this.$toast.showRed("请选择医院后添加设备")

    },

    onDelete(id) {
      this.deleteId = id
      this.deleteDialogVisible = true
    },

    addDeviceCloseDialog(value) {
      this.$toast.showGreen('设备添加成功');
      this.dialogVisible = value
      this.keyword = ''
      this.currentPage = 1
      this.getDevicePagingList()

    },
    deleteDeviceCloseDialog(value) {
      this.$toast.showGreen('设备删除成功');
      this.deleteDialogVisible = value
      this.getDevicePagingList()
    },
    onCloseDialog(value) {
      this.dialogVisible = value
    },
    onCloseDeleteDialog(value) {
      this.deleteDialogVisible = value
    },

    // method
    async getDevicePagingList() {
      try {
        const params = {
          keyword: this.keyword,
          hospitalId: this.hospitalId,
          pageNum: this.currentPage,
          pageSize: 10,
        };
        const data = await this.$api.getDeviceList(params)
        this.dataArray = this.composeDataArray(data.list);
        this.totalPage = data.total;
        this.currentPage = data.pageNum;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },



    composeDataArray(rawDataArray) {
      return rawDataArray.map((item) => {
        return {
          onlineStatus: item.onlineStatus,
          deviceSN: placeholderText(item.sn),
          userName: placeholderText(item.userName),
          lastTime: placeholderText(DateTool.timeStrToStr(item.lastUseTime, 'YYYY-MM-DD')),
          hospitalName: placeholderText(item.hospitalName),
          id: item.id,
        }
      })
    }


  }
};
</script>

<style lang="scss" scoped>
.device-list {
  height: 100vh;
  position: relative;
  box-sizing: border-box;
  padding: 84px 40px;

  &-search {
    margin-left: auto;

    position: absolute;
    top: 0px;
    right: 40px;
    height: 84px;
    width: 369px;


    display: flex;
    align-items: center;
    justify-content: space-between;

    &-input {
      width: 270px;
      height: 34px;
      display: flex;
      justify-content: flex-end;

      &-input {
        width: 270px;
        height: 34px;


        ::v-deep .el-input__inner {
          height: 34px;
          padding-right: 29px;
          font-size: 16px;
          color: #000000;
        }

        ::v-deep .el-icon-search:before {
          content: "";
          cursor: pointer;
          background: url('../../../../assets/imgs/device-search.png') center center no-repeat;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &-container {

        position: absolute;
        cursor: pointer;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;

        &-icon {
          margin: auto;

        }
      }
    }




    &-btn {
      width: 87px;
      height: 32px;
      border: 1px solid #2E6BE6;
      border-radius: 6px;
      font-size: 16px;
      color: #2E6BE6;
      background-color: #fff;
      font-weight: 400;
    }

    &-btn:active {
      transform: scale(0.97);
      width: 87px;
      height: 32px;
      border: 1px solid #4C2DE6;
      border-radius: 6px;
      font-size: 16px;
      color: #2E6BE6;
      background-color: #ECF5FF;
      font-weight: 400;
    }
  }

  &-table {

    &-check-icon {
      position: relative;
      top: 3px;
      width: 20px;
      height: 20px;
    }

    /deep/.el-table--border {
      border: 1px solid #E7E7E7 !important;
    }

    //header-cell
    /deep/.el-table th.el-table__cell>.cell {
      color: #909090;
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
      margin-left: 14px;
    }


    //cell
    /deep/ .el-table td.el-table__cell div {
      color: rgba($color: #000000, $alpha: 0.9);
      font-size: 14px;
      margin-left: 14px;
    }
  }

  &-pagination {
    position: absolute;
    right: 40px;
    width: 100%;
    margin-top: 52px;
    display: flex;
    justify-content: flex-end;
  }

  &-dialog {
    margin-top: 20vh;

    ::v-deep .el-dialog__body {
      // width: 365px;
      padding: 0px;
    }

    ::v-deep .el-dialog__header {
      display: none;
    }
  }

}
</style>