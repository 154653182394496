<template>
  <div class="setting">
    <component class="setting-device-list" :is="activeName"></component>
    <el-tabs v-model="activeName" class="setting-top-tabs" @tab-click="handleClick">
      <el-tab-pane label="设备列表" name="deviceList" />
      <el-tab-pane label="操作日志" name="operationLog" />
      <el-tab-pane label="系统日志" name="systemLog" />
      <el-tab-pane label="调参日志" name="adjustmentLog" />
    </el-tabs>
  </div>
</template>
  
<script>
import { TabPane, Tabs } from "element-ui";
import DeviceList from "./device-list/device-list.vue";

export default {
  components: {
    elTabPane: TabPane,
    elTabs: Tabs,
    DeviceList,
    OperationLog: () => import('./operation-log/operation-log.vue'),
    AdjustmentLog: () => import('./adjustment-log/adjustment-log.vue'),
    SystemLog: () => import('./system-log/system-log.vue')
  },
  data() {
    return {
      activeName: 'deviceList'
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    }
  },
};
</script>
  
<style lang="scss" scoped>
.setting {
  background-color: white;
  color: black;
  font-size: 28px;
  overflow: hidden;
    overflow-y: auto;
  display: flex;
  flex-direction: column;

  &-top-tabs {
    position: absolute;
    padding: 20px 0px 20px 45px;
    color: #000000;

    ::v-deep .el-tabs__nav-wrap::after {
      background-color: #ffffff
    }

    ::v-deep .el-tabs__item {
      font-size: 14px;
      font-weight: 500;
    }

    ::v-deep .el-tabs__item.is-active {
      color: #1677FF;
    }

    ::v-deep .el-tabs__content {
      height: 0px;
      visibility: hidden
    }

    ::v-deep .el-tabs__active-bar {
      background-color: #1677FF;

    }
  }


}
</style>